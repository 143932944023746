import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";

export const query = graphql`
  query {
    listazlecen: file(relativePath: { eq: "listazlecen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kanban: file(relativePath: { eq: "kanban.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    backlog: file(relativePath: { eq: "backlog.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zlecenia: file(relativePath: { eq: "zlecenie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kalendarzzlecen: file(relativePath: { eq: "kalendarzzlecen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Projekty = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Zarządzanie projektami i zleceniami"
      headline="Własne procesy, widok kanban, kalendarz zleceń"
    >
      <SEO
        title="Zarządzanie projektami i zleceniami"
        description="Zarządzanie projektami i zleceniami w agencji reklamowej oraz marketingowej ✅ Własne typy procesów • Widok Kanban • Kalendarz zleceń • Zapytaj o bezpłatne demo!"
        keywords={["zarządzanie projektami"]}
      />
      <p>
        Dobrze <strong>zarządzany projekt</strong> to podstawa sukcesu w każdej{" "}
        <strong>agencji marketingowej</strong>. To samo dotyczy{" "}
        <strong>zarządzania zleceniami</strong>, które często z dnia na dzień
        otrzymuje <strong>agencja reklamowa</strong>. Oba podejścia wymagają
        sprawnego zarządzania, aby dostarczyć klientowi na czas produkty i
        usługi. Nieważne, czy chodzi o&nbsp;wykonanie prostego{" "}
        <strong>billboardu, zaprojektowanie i druk ulotki</strong> czy
        prowadzenie skomplikowanego procesu brandingu. Poradzisz sobie nawet
        z&nbsp;opracowaniem <strong>strategii marketingowej</strong> dla
        klienta. Po prostu z <strong>Empireo masz wszystko pod kontrolą</strong>
        .
      </p>

      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[
          data.kanban,
          data.backlog,
          data.zlecenia,
          data.listazlecen,
          data.kalendarzzlecen,
        ]}
        alts={[
          "Zarządzanie projektami i zleceniami w agencji marketingowej, sprawne planowanie projektów w agencji reklamowej, zarządzanie zleceniami w agencji reklamowej, lista zleceń dla marketingu, kalendarz zleceń usprawniający planowanie pracy w zespole marketingu",
        ]}
      />
      <h3>Kluczowe możliwości:</h3>
      <p>
        <ul>
          <li>
            <strong>Tworzenie własnych typów procesów / zleceń </strong>- czyli
            możliwość definiowania wielu procesów z różnymi etapami.
          </li>
          <li>
            <strong>Możliwość dodawania własnych atrybutów do zlecenia</strong>{" "}
            - co pozwoli odzwierciedlić Twój obecny proces.
          </li>
          <li>
            <strong>Widoki Kanban (tablica zleceń)</strong> - czyli wizualizacja
            przepływu realizowanych zleceń, która ułatwia ich monitorowanie oraz
            koordynację. Możesz tworzyć ich wiele, dla różnych działów.
          </li>
          <li>
            <strong>Kalendarz zleceń</strong> - czyli szybki sposób na
            sprawdzenie obciążenia Twojego zespołu i efektywne planowanie nowych
            zleceń.
          </li>
          <li>
            <strong>
              Możliwość załączania plików do zlecenia i karty klienta{" "}
            </strong>
            - czyli sposób na przechowywanie kluczowych informacji w jednym
            miejscu.
          </li>
        </ul>
      </p>
      <p
        style={{
          marginTop: "30px",
        }}
      >
        Empireo umożliwi Ci{" "}
        <strong>zebranie w jednym miejscu wszystkich zleceń i projektów</strong>{" "}
        Twojej agencji oraz znacznie ułatwi monitorowanie statusu ich
        realizacji. Stwórz z naszym rozwiązaniem{" "}
        <strong>spójny standard obsługi zleceń</strong> i zarządzaj nawet
        najbardziej <strong>złożonymi projektami</strong>.
      </p>
    </FunctionPageLayout>
  );
};

export default Projekty;
